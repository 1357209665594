import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ZU-hEctCUVs">
    <SEO title="The Day that Changed the World - The Week That Changed the World" />
  </Layout>
)

export default SermonPost
